<template>
    <Checkbox v-model="checked" value="1" :binary="true" @change="onChanged"></Checkbox>
</template>
<script>
import Checkbox from 'primevue/checkbox';
import { PwgsApi } from '../../../../services/PwgsApi';

export default {
    components: {
        Checkbox
    },
    props: [
        'props',
        'datos',
    ],
    data() {
        return {
            checked: false
        }
    },
    methods: {
       async onChanged() {

            /*  modulos/pwgsapi/index.php/usuarios/:id/estado */
            const api = new PwgsApi;
            const subidadatos = {valor:'1'};
            if (this.checked == true) {
                subidadatos.valor = '0';
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Marcado por defecto', life: 2000 });
            }
            else {
                subidadatos.valor = '1';                
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Marcado NO por defecto', life: 2000 });
            }
           await api.put('condiciones/' + this.datos.id_condicion + '/por-defecto', subidadatos);
            

        }
    },
    mounted() {
        const datos = this.datos;
        this.checked = this.props.checked(datos);
    }
}
</script>